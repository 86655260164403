import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AppLayout from "../layout";

export const Router = () => {

  const Regist = React.lazy(() => import(/* webpackChunkName: "views-home" */ "../views/regist"));
  const Login = React.lazy(() => import(/* webpackChunkName: "views-home" */ "../views/login"));
  const Home = React.lazy(() => import(/* webpackChunkName: "views-home" */ "../views/home"));
  const GamingPcs = React.lazy(() => import(/* webpackChunkName: "views-home" */ "../views/gamingPcs"));
  const User = React.lazy(() => import(/* webpackChunkName: "views-home" */ "../views/user"));
  const PcDetails = React.lazy(() => import(/* webpackChunkName: "views-home" */ "../views/gamingPcs/pcDetails"));
  
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <AppLayout >
          <Routes>
            <Route index element={<Navigate to="/home" />} />
            <Route exact path="/regist">
              <Route index element={<Regist />} />
            </Route>
            <Route exact path="/login">
              <Route index element={<Login />} />
            </Route>
            <Route exact path="/home">
              <Route index element={<Home />} />
            </Route>
            <Route exact path="/user">
              <Route index element={<User />} />
            </Route>
            <Route exact path="/gamingPcs">
              <Route index element={<GamingPcs />} />
            </Route>
            <Route exact path="/pc-details/:id">
              <Route index element={<PcDetails />} />
            </Route>
          </Routes>
        </AppLayout>
      </BrowserRouter>
    </Suspense>
  );
};
