import axios from 'axios'
import { toast } from "react-toastify"
import { logout } from 'redux/store/apps/auth'
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const AxiosInterceptor = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  axiosConfig.interceptors.response.use(function (response) {
    return response
  }, function (error) {

    if(error.response.status === 401){
      dispatch(logout())
      toast.error(t('general.logout'));
    }else{
      return Promise.reject(error)
    }
  })

  return children;
}

export default axiosConfig;
export { AxiosInterceptor }
