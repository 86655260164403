import React from "react";
import { Row, Col } from "reactstrap";
import { ReactComponent as DotsWhite } from "assets/img/icons/dotsWhite.svg";
import ShadowAnimation from "../animations/shadowAnimation";

const DotsText = ({ text, size,footer }) => {
  return (
    <Row className="border border-success">
      <ShadowAnimation>
        <Col className={`d-flex flex-row min-width-0 ${size === "large" ? "dots-text-large" : "dots-text"}`}>
          <div className={`d-flex align-items-center ${size === "large" ? "dots-text-icon-large" : "dots-text-icon"}`}>
            <DotsWhite />
          </div>
          <div className={` my-auto truncate ${size === "large" ? "dots-text-title-large text-1 weight-300" : `dots-text-title ${footer ? "text-5" : "text-3"} weight-300`}`}>
            {text}
          </div>
        </Col>
      </ShadowAnimation>
    </Row>
  );
};

export default DotsText;
