// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers} from 'redux'
import storage from "redux-persist/lib/storage"
import {persistStore, persistReducer} from "redux-persist"

// ** Reducers

import appConfig from './apps/appConfig'
import products from './apps/products'
import auth from './apps/auth'
import user from './apps/user'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["auth"]
}

const rootReducer = combineReducers({
  appConfig,
  products,
  auth,
  user
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
})

export const persistor = persistStore(store);
