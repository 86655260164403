import React from "react";
import { Button, Row } from "reactstrap";
import ShadowAnimation from "../animations/shadowAnimation";
import ToggleShadowAnimation from "../animations/toggleShadowAnimation";

const ButtonCuttedCorners = ({ text, buttonType, request, image, color,toggle ,perfectPc,id,textSize}) => {

  return (
    toggle ? 
    <ToggleShadowAnimation perfectPc={perfectPc} id={id} request={request}>
      <Button className={buttonType === "small" ? "m-0 p-0 small-cutted-button d-flex align-items-center justify-content-center" : "m-0 p-0 large-cutted-button d-flex align-items-center justify-content-center"}>
        <div className={`${color} h-100 w-100 m-0 p-0 d-flex align-items-center justify-content-center`}>
          <Row className={`m-0 d-flex align-items-center justify-content-center ${text ? buttonType === "small" ? `py-1 px-3 ${textSize ? textSize : "text-8"}` : `py-2 px-4 ${textSize ? textSize : "text-7"}` : "p-0"} text-nowrap`}>{text ? text : image}</Row>
        </div>
      </Button>
    </ToggleShadowAnimation>
    :
    <ShadowAnimation>
      <Button onClick={request} className={buttonType === "small" ? "m-0 p-0 small-cutted-button d-flex align-items-center justify-content-center" : "m-0 p-0 large-cutted-button d-flex align-items-center justify-content-center"}>
        <div className={`${color} h-100 w-100 m-0 p-0 d-flex align-items-center justify-content-center`}>
          <Row className={`m-0 d-flex align-items-center justify-content-center ${text ? buttonType === "small" ? `py-1 px-3 ${textSize ? textSize : "text-8"}` : `py-2 px-4 ${textSize ? textSize : "text-7"}` : "p-0"} text-nowrap`}>{text ? text : image}</Row>
        </div>
      </Button>
    </ShadowAnimation>
  );
};

export default ButtonCuttedCorners;
