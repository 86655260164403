import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'utils/apiHelper';

const key = 'UpTech-4f4fc10040c56e33b25bb1a783803d2a';
const cookie = 'def502003a4d7c302f2a74ffb05409daf631d30c497ce16de8325ddb5ea4dfc179349d1635d44c7894f7515929752b5131146fa812ddd736ee5cd83c0e57a33921f8cd57ee7c92e32d0ad36d649c20ab96cc7224ca234206e1ea52fc318585df47ee6917b5f14b1dc82870686394b9be57150bc84283c0be9456e9cfc6e9b6a3b1b12b4ba978089a3dd037eee31afa329bbfaeee969cb9d841eea8e0bb9677d0728eb730fd0750700df2166ce4edc961cc01662e108b89216b5b2c09bd9f2cf345bd1fd8aecbfd17acc26c2f6f14ec233f2779fb6e063af461cccc3531f2d35eb4302d6ee6fb4caea61fb8aab753f61e0895df8ed0c3637ae2dc72b871ea29fc95488c721f3e43cd435aa92e279c41445a26d7ed73bc3858ed7bf15f62b3b125c0c87dd0cb0afdc37f5d1368b0a45116aee1f82d080673e86d669b52c6e6cb307c7122c1d2843ed632d00b5534638396b16af5f97fd9972cef424724aec2b14f19bdc8df323cf37ceba4ec1c72aae1e4329dabe8667b429006ba35ad43b814c392d29fb21d16eecfa52aff3409f31cebab3b03f788b44a7fb411b3856b6984ce67068683e9ebc16a3367c441c239c48145fc0b3730f7c5a27c772a66b7a851a89017cadf100a03ac4faa97425751c9aa79edb0a8567d6f416c64a57128be90a3c203d4ff0b668d299cbcc0b7'


// ** User Info
export const userInfo = createAsyncThunk('user/info', async (params, { getState, dispatch }) => {
  const response = await api.get('accountInfo', { 
    withCredentials: true,
  })
  return response.data;
})

// ** Edit User Info
export const updateUser = createAsyncThunk('user/updateUser', async (params, { getState, dispatch }) => {

  const config = { headers: { "Content-Type": "text/plain" }};
  const response = await api.post('accountedit', {
    email: params.email,
    password: params.password,
    firstName: params.firstName,
    lastName: params.lastName,
    birthday: params.birth,
    gender: params.gender
  }, config)

  return response.data;
})

export const appAuthSlice = createSlice({
  name: 'user',
  initialState: {
    userData: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(userInfo.fulfilled, (state, action) => {
      state.userData = action.payload
    })
  }
})

export default appAuthSlice.reducer