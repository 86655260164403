import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'utils/apiHelper';

// ** Fetch Products
export const fetchProducts = createAsyncThunk('products/fetchProducts', async (params, { getState, dispatch }) => {
  const response = await api.get(`categoryProducts?id_category=${params?.categoryId}&page=1&with_all_images=0&image_size=home_default`)
  return response.data.psdata;

})

export const appProductsSlice = createSlice({
  name: 'products',
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default appProductsSlice.reducer